import React, { useState } from 'react';

import {ContentHeader} from "../ContentHeader";
import {ContentCard} from "../ContentCard";

import "./Proservices.scss";
 


export const Proservices = () => {
    const [psdata] = useState([
        {
            "id": 1,
            "name": "Консультация",
            "description": ""
        
        },
        {
            "id": 2,
            "name": "Правовой анализ документов",
            "description": ""
        
        },
        {
            "id": 3,
            "name": "Оценка правовых рисков",
            "description": ""
            
        
        },
        {
            "id": 4,
            "name": "Досудебное урегулирование спора",
            "description": ""
        
        },
        {
            "id": 5,
            "name": "Подготовка документов правового характера",
            "description": ""
        
        },
        {
            "id": 6,
            "name": "Представление интересов доверителя в суде",
            "description": ""
        
        },
        {
            "id": 7,
            "name": "Участие в исполнительном производстве",
            "description": ""
        
        },
        {
            "id": 8,
            "name": "Подготовка проектов договоров",
            "description": ""
        },
        {
            "id": 9,
            "name": "Абонентское сопровождение юридических лиц",
            "description": ""
        }
    ]);


    return (
       
        <section id="3" className="content-wrapper">
            <div className="content-block proservices">
            <ContentHeader titleCont="Услуги" />
            <div className="content-block-item">

                <ul>
                    {
                        psdata.map((item) => {
                            return (
                            <li
                            key={item.id}
                            > 
                                <ContentCard
                                className="content-list-item"
                                title={item.name}
                                >         
                                </ContentCard>                   
                                
                                </li>
                                    )         
                        })
            }                  
                </ul>
            </div>
            </div>
        </ section> 
    )
}
