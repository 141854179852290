import React from 'react'
import classNames from "classnames";

import "./ContentCard.scss"

export const ContentCard = ({title, srcImg, altImg, className, onClickTitle, children}) => {
    return (
      <>
      <div className={classNames(className)}>
        <div className="content-card-title" onClick={onClickTitle}>
            <h3>{title}</h3>
          </div>
            {<div className="content-card-item">
                <div className="content-card-description">
                  {children}
                </div>
                {/* {
                srcImg && 
                  <div className="content-card-item-pic">
                  <img src={srcImg} alt={altImg}></img>
                  </div>
                } */}
                
                  </div>
            }
        </div>
        </>
    
    )
  }
  